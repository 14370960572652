import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class PropertiesService {

  private static readonly DEFAULT_APP_CONFIG_PATH: string = "assets/configs/appConfig.properties.json";

  public static properties: object = {};

  constructor(@Optional() @Inject("propertiesUrl") private propertiesUrl: string, private http: HttpClient) {

    if (this.propertiesUrl == null) {
      this.propertiesUrl = PropertiesService.DEFAULT_APP_CONFIG_PATH;
    }
  }

  setPropertiesUrl(propertiesUrl: string): void {
    this.propertiesUrl = propertiesUrl;
    console.info("PropertiesUrl has been set to: " + propertiesUrl);
  }

  readProperties(path: string): Observable<unknown> {
    return this.http.get(path).pipe(catchError((e: Error) => {
      console.error("ERROR: Properties file " + path + " not found.");
      return observableThrowError(e);
    }));
  }


  getAppConfig(): Observable<unknown> {
    return this.readProperties(this.propertiesUrl);
  }

  readAllProperties(): Observable<void> {
    return this.readProperties(this.propertiesUrl).pipe(
      map((properties: unknown) => {
        PropertiesService.properties = (properties as object);
        return;
      })
    );
  }


}
